import axios from '@/plugins/axios'

const baseURL = process.env.VUE_APP_SCORING_API;

class Scoring
{
	datos_chart(id) {
		return axios.get(baseURL+'/ocr/solicitud/'+id+'/raw')
	}

	/**
     * get data of Finerio by solicitud_id
     * @returns {Promise}
     */
	getDataOpenBanking(id) {
		return axios.get(baseURL+'/finerio/solicitud/'+id)
	}
}

export default new Scoring();